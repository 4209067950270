import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { BackButton, Section, TopNavbar } from 'src/components/common';
import { useDashboardGetStudentNotificationData, useNotificationLogFindAll } from 'src/generated/endpoint';
import { NotificationLog } from 'src/generated/model';
import { meState } from 'src/store';
import { makeDateToString } from 'src/util/time';

export const NotificationPage = () => {
  const me = useRecoilValue(meState);
  const { data: dashboardItem } = useDashboardGetStudentNotificationData();
  const { data } = useNotificationLogFindAll();

  const notconfirmedUrls = ([] as any[])
    .concat(dashboardItem?.unreadActivityNotice?.map((el) => `/student/activityv3/${el.id}`) || [])
    .concat(dashboardItem?.unreadBoard?.map((el) => `/student/board/${el.id}`) || [])
    .concat(dashboardItem?.unreadNewsletterNotice?.map((el) => `/student/newsletter/${el.id}`) || [])
    .concat(dashboardItem?.unreadNotice?.map((el) => `/student/notice/${el.id}`) || []);

  return (
    <>
      <div className="md:hidden">
        <TopNavbar
          title="알림"
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
        />
      </div>
      <div className="scroll-box h-screen-8 overflow-y-auto bg-[#FFF8F2]">
        <div className=" px-2 pb-2 pt-4 text-lg font-bold">확인하지 않은 알림</div>
        <Section className="space-y-2">
          {!!dashboardItem?.unreadActivityNotice?.length && (
            <Link to={'/student/activityv3'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">활동</div>
                <div className="text-sm text-gray-500">
                  확인하지 않은 활동이 {dashboardItem.unreadActivityNotice.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.unreadBoard?.length && (
            <Link to={'/student/board'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">학급게시판</div>
                <div className="text-sm text-gray-500">
                  학급게시판에 새로운 글이 {dashboardItem.unreadBoard?.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.unreadNewsletterNotice?.length && (
            <Link to={'/student/newsletter'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">가정통신문</div>
                <div className="text-sm text-gray-500">
                  확인하지 않은 가정통신문이 {dashboardItem.unreadNewsletterNotice.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.unreadChatMessageCount && (
            <Link to={'/student/chat'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">메시지</div>
                <div className="text-sm text-gray-500">
                  읽지 않은 메시지가 {dashboardItem.unreadChatMessageCount}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.unreadNotice?.length && (
            <Link to={'/student/notice'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">공지사항</div>
                <div className="text-sm text-gray-500">
                  새로운 공지사항이 {dashboardItem.unreadNotice?.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.requestConfirmAbsents?.length && (
            <Link to={'/student/absent'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">결석신고서</div>
                <div className="text-sm text-gray-500">
                  미결제된 결석신고서가 {dashboardItem.requestConfirmAbsents.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.requestConfirmOutings?.length && (
            <Link to={'/student/absent'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">확인증</div>
                <div className="text-sm text-gray-500">
                  미결제된 확인증이 {dashboardItem.requestConfirmOutings.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.requestConfirmFieldTrips?.length && (
            <Link to={'/student/absent'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">체험학습 신청서</div>
                <div className="text-sm text-gray-500">
                  미결제된 체험학습 신청서가 {dashboardItem.requestConfirmFieldTrips.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
          {!!dashboardItem?.requestConfirmFieldTripResults?.length && (
            <Link to={'/student/absent'}>
              <div className="cursor-pointer rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className=" font-semibold">체험학습 결과보고서</div>
                <div className="text-sm text-gray-500">
                  미결제된 체험학습 결과보고서가 {dashboardItem.requestConfirmFieldTripResults.length}건 있습니다.
                </div>
              </div>
            </Link>
          )}
        </Section>
        <div className=" px-2 pb-2 pt-4 text-lg font-bold">모든 알림</div>
        <Section className="space-y-2">
          {data?.map((log: NotificationLog) => (
            <Link key={log.id} to={log.url}>
              <div className="flex cursor-pointer items-center justify-between space-x-2 rounded-xl border border-gray-300 bg-white px-4 py-2">
                <div className="w-full overflow-hidden">
                  <div className="flex  w-full items-center space-x-1">
                    <div className="max-w-full truncate font-semibold">{log.title}</div>
                    {notconfirmedUrls.includes(log.url) && (
                      <small className="inline-block h-4 w-4 rounded-full bg-red-500 text-center text-xs font-light text-white">
                        N
                      </small>
                    )}
                  </div>

                  <div className="text-sm text-gray-500"> {log.body}</div>
                </div>
                <div className="min-w-max text-12 text-gray-500"> {makeDateToString(log.createdAt)}</div>
              </div>
            </Link>
          ))}
        </Section>
      </div>
    </>
  );
};
